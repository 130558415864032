import { useEffect, useState } from 'react'
import classNames from 'classnames'
import Link from 'next/link'
import Image from 'next/future/image'

import {
  Banners,
  BannersLayoutEnum,
  Column,
  Container,
  DataBody,
  Row,
  Title
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  addToHomeData,
  clickedPromos,
  getContentfulImageCategories,
  getParamsInURL,
  trigger,
  triggerLoginEvent,
  useCategories,
  useContentful,
  useOnScreen,
  UseQueryOptions,
  useSession
} from '@smu-chile/pkg-unimarc-hooks'
import {
  SendPromoDataProps,
  SendPromoDataImageBanner,
  BannerHomeObj
} from 'shared/interfaces/Home'
import {
  getBannerImages,
  getBannerItemsToShow,
  getBannerShowDots,
  getContainerBackgroundColor,
  getContainerMaxWidth
} from './helpers'
import { DropdownWithRoundContent } from './helpers/DropdownWithRound'
import { LocalProductsBannerContent } from './helpers/LocalProductsBanner'
import {
  MEMBERSHIP_ID,
  isProd
} from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import styles from './BannersWeb.module.css'
import router from 'next/router'

const paramsApp = getParamsInURL('source')

const clickBanner = (promosData, isLoggedIn, isLoading) => {
  const { reference, promotionId, promotionName } = promosData
  const redirectTo = (path) => {
    return router.push(`${window.location.pathname}${path}`)
  }

  const handleDefaultFlow = () => {
    if (!isLoading && isLoggedIn) {
      trigger({
        eventType: 'openMembershipModal',
        data: { eventContext: 'Landing' }
      })
    } else {
      triggerLoginEvent({ eventType: 'loginModal', data: { show: true } })
    }
  }

  const handleAppSourceFlow = () => {
    const path =
      !isLoading && !isLoggedIn
        ? '?source=APP&GoSSO'
        : '?source=APP&GotoMembership'
    redirectTo(path)
  }

  if (reference && promotionId && promotionName) {
    sessionStorage.setItem(
      reference,
      JSON.stringify({
        promotionId: promotionId,
        promotionName: promotionName
      })
    )
  }

  if (promosData?.promotionId === MEMBERSHIP_ID.BANNER_HOME) {
    if (!paramsApp) {
      handleDefaultFlow()
    } else {
      handleAppSourceFlow()
    }
  }

  clickedPromos(promosData)
}

const validateLayout = (
  data,
  isMobile,
  cfIndex,
  saleChannel,
  isLoggedIn,
  isLoading
) => {
  const contentObject = isMobile ? 'imageMobile' : 'imageDesktop'
  const imageBanner = data?.['imageBanner']?.[0]
  const pathMembershipLanding = isProd ? 'membresia' : 'membresia-prueba'

  if (data['layout'] == 'uno solo' && imageBanner) {
    const itemSaleChannels = imageBanner?.saleChannels
    if (itemSaleChannels && !itemSaleChannels.includes(saleChannel)) return null
    const collectionName =
      imageBanner['collectionName'] ?? imageBanner['collectionName']

    const banner: string =
      imageBanner?.[contentObject]?.['fields']?.['file']?.['url']
    return {
      bannerOnlyOne: {
        alt: imageBanner['alt'],
        banner,
        isMobile,
        onRedirect: () => {
          clickBanner(
            {
              ...imageBanner,
              locationId: cfIndex
            },
            isLoggedIn,
            isLoading
          )
        },

        url: paramsApp
          ? `${pathMembershipLanding}?source=APP&collectionName=${collectionName}`
          : imageBanner?.['reference']
      }
    }
  }

  if (
    ['grid', 'carousel', 'occasion of consumption', 'collage'].includes(
      data['layout']
    )
  ) {
    const nameType = ['grid', 'occasion of consumption', 'collage'].includes(
      data.layout
    )
      ? 'bannersGrid'
      : 'bannersCarousel'
    const bannerData: Array<{ img: string; url: string }> = data['imageBanner']
      ?.map((bannerInfo, index) => {
        const locationId = `${cfIndex} - ${index + 1}`

        const collectionName = bannerInfo['collectionName']
          ? bannerInfo['collectionName']
          : null
        return {
          alt: bannerInfo['alt'],
          creativeName: bannerInfo['creativeName'],
          creativeSlot: bannerInfo['creativeSlot'],
          img: bannerInfo[contentObject]['fields']['file']['url'],
          locationId: locationId,
          promotionId: bannerInfo['promotionId'],
          promotionName: bannerInfo['promotionName'],
          url: paramsApp
            ? `${pathMembershipLanding}?source=APP&collectionName=${collectionName}`
            : bannerInfo['reference'],
          saleChannels: bannerInfo['saleChannels'],
          onRedirect: () => {
            clickBanner({ ...bannerInfo, locationId }, isLoggedIn, isLoading)
          }
        }
      })
      .filter((banner) => {
        const saleChannels = banner['saleChannels']
        if (!saleChannels || saleChannels.includes(saleChannel)) return true
        return false
      })

    if (!bannerData.length) return null

    return { [nameType]: bannerData }
  }

  if (data['layout'] === '100% ours') {
    const handleClick = () => {
      if (data?.['urlPath']) {
        window.location.href = data['urlPath']
      } else if (data['promotionId']) {
        clickBanner(
          {
            ...data
          },
          isLoggedIn,
          isLoading
        )
      }
    }
    return {
      backgroundColor: data['color'],
      buttonLabel: data['textButton'],
      description: data['subtitle'],
      descriptionFontColor:
        data['fontColor'] || getGlobalStyle('--color-base-black'),
      imagesSrcs: getBannerImages(data['imageBanner'], contentObject),
      onClick: () => {
        handleClick()
      },
      title: data['title'],
      titleFontColor: data['fontColor'] || getGlobalStyle('--color-base-black')
    }
  }

  return null
}

const getCategoriesData = ({ categoriesApp, resultAisles }) => {
  const categoriesDataImage = getContentfulImageCategories(categoriesApp?.data)

  const data = Array.isArray(resultAisles?.data?.data)
    ? resultAisles.data.data.map((item) => {
        let url = item.url
        if (/\.com\.br(.+)/.test(url)) {
          url = `/category` + /\.com\.br(.+)/.exec(url)[1]
        }
        const categoryDataImage = categoriesDataImage?.find(
          ({ idCategory }) => {
            return idCategory === item.id
          }
        )
        return {
          href: url,
          image: categoryDataImage?.categoryImage?.file?.url,
          name: item.name,
          creativeName: categoryDataImage?.creativeName,
          creativeSlot: categoryDataImage?.creativeSlot,
          promotionId: categoryDataImage?.promotionId,
          promotionName: categoryDataImage?.promotionName
        }
      })
    : []

  return data
}

const getDropdownData = ({
  data,
  isMobile
}: {
  isMobile: boolean
  data: BannerHomeObj
}): DataBody[] => {
  return (
    Array.isArray(data?.['imageBanner']) &&
    data?.['imageBanner'].map((item) => {
      const typeOfImage = isMobile ? 'imageDesktop' : 'imageMobile'
      return {
        href: item?.['reference'],
        image: item?.[typeOfImage]?.fields?.file?.url,
        name: item?.['creativeName'],
        creativeName: item?.['creativeName'],
        creativeSlot: item?.['creativeSlot']
      }
    })
  )
}

const sendPromoData = ({ cfIndex, data, ref }: SendPromoDataProps) => {
  let promoData: SendPromoDataImageBanner = data

  if (data['layout'] == 'uno solo' && data['imageBanner'][0]) {
    const bannerImage = data['imageBanner'][0]
    promoData = {
      creativeName: bannerImage['creativeName'],
      creativeSlot: bannerImage['creativeSlot'],
      locationId: cfIndex,
      promotionId: bannerImage['promotionId'],
      promotionName: bannerImage['promotionName'],
      saleChannels: bannerImage['saleChannels']
    }
  }
  addToHomeData({ ref, promoData })
}

export interface PropsBannersWeb {
  cfIndex?: number
  data: BannerHomeObj
  isMobile: boolean
  isPriority?: boolean
  saleChannel?: string
  titleSizes?: object
}

export const BannersWeb = ({
  cfIndex,
  data,
  isMobile,
  isPriority,
  saleChannel
}: PropsBannersWeb) => {
  const { isLoading, isLoggedIn } = useSession()
  const [maxWidthContainer, setMaxWidthContainer] = useState<string>('')
  const [isOpenDropDown, setIsOpenDropDown] = useState<boolean>(false)
  const { elementRef, isIntersecting } = useOnScreen({ rootMargin: '0px' })
  const {
    elementRef: refProductsBanner,
    isIntersecting: isIntersectingProductsBanner
  } = useOnScreen({ rootMargin: '0px' })
  const { elementRef: refBanner, isIntersecting: isIntersectingBanner } =
    useOnScreen({ rootMargin: '0px' })
  const isCategories: boolean =
    data['layout'] == 'DropDownWithRound' && data['idReference'] == 'categories'
  const reactQueryCategories: UseQueryOptions = { enabled: isCategories }
  const resultAisles = useCategories({
    level: 2,
    reactQuery: reactQueryCategories
  })
  const categoriesApp = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CATEGORIES_TYPE
    },
    reactQuery: reactQueryCategories
  })

  let dataDropdownRound: DataBody[] | [] = []
  if (data['layout'] == 'DropDownWithRound') {
    if (isCategories)
      dataDropdownRound = getCategoriesData({ resultAisles, categoriesApp })
    if (!isCategories) dataDropdownRound = getDropdownData({ data, isMobile })
  }

  const isAllowDropdownAction = dataDropdownRound.length > 4
  const bannerProps = validateLayout(
    data,
    isMobile,
    cfIndex,
    saleChannel,
    isLoggedIn,
    isLoading
  )

  useEffect(() => {
    if (
      (data['layout'] === 'collage' && data['imageBanner']?.length === 5) ||
      (data['layout'] === 'collage' && data['imageBanner']?.length === 7)
    ) {
      setMaxWidthContainer('1284px')
    } else {
      setMaxWidthContainer(getGlobalStyle('--width-max-desktop'))
    }
  }, [data])

  if (!bannerProps && !isCategories) return null

  const hasNoTitle = !(data.title || data.subtitle)
  const backgroundColor: string =
    data['layout'] === 'occasion of consumption'
      ? data['color']?.toString()
      : 'transparent'

  // props for DropdownWithRoundContent
  const dropdownWithRoundContentProps = {
    isIntersecting,
    dataBody: dataDropdownRound,
    handleChangeDropDown: () => {
      setIsOpenDropDown(!isOpenDropDown)
    },
    isOpen: isOpenDropDown,
    isMobile,
    showMore: isAllowDropdownAction,
    title: data['title']?.toString(),
    linkWrapper: Link,
    cfIndex: cfIndex,
    onRedirect: () => {
      clickBanner({ ...dataDropdownRound }, isLoggedIn, isLoading)
    }
  }

  if (data['layout'] == 'DropDownWithRound') {
    return (
      <Column
        alignItems='center'
        backgroundColor={backgroundColor}
        id={getBemId(
          'bannerWeb',
          data['layout'].split(' ').join(''),
          `${cfIndex}`
        )}
        maxWidth={getGlobalStyle('--width-max-desktop')}
        ref={elementRef}
      >
        <DropdownWithRoundContent {...dropdownWithRoundContentProps} />
      </Column>
    )
  }

  // props for LocalProductsBannerContent
  const localProductsBannerContentProps = {
    isIntersectingProductsBanner,
    backgroundColor: bannerProps.backgroundColor,
    buttonLabel: bannerProps.buttonLabel,
    description: bannerProps.description,
    descriptionFontColor: bannerProps.descriptionFontColor,
    imagesSrcs: bannerProps.imagesSrcs,
    linkWrapper: Link,
    onClick: bannerProps.onClick,
    title: bannerProps.title,
    titleFontColor: bannerProps.titleFontColor
  }

  if (data['layout'] === '100% ours') {
    return (
      <Column
        alignItems='center'
        id={getBemId('bannerWeb', '100percentOurs', `${cfIndex}`)}
        maxWidth={getGlobalStyle('--width-max-desktop')}
        padding={isMobile ? '' : '0 20px'}
        ref={refProductsBanner}
      >
        <LocalProductsBannerContent {...localProductsBannerContentProps} />
      </Column>
    )
  }

  const {
    color: bannerBackgroundColor,
    expand: bannerExpand,
    infinite: bannerInfinite,
    itemsToShow: bannerItemsToShow,
    layout: bannerLayout,
    showDots: bannerShowDots,
    zoomIn: bannerZoomIn
  } = data as {
    color: string
    expand: boolean
    infinite: boolean
    itemsToShow: number
    layout: string
    showDots: boolean
    zoomIn: boolean
  }

  if (data?.imageBanner?.length === 0) {
    return null
  }

  const justifyContentBanners =
    data['layout'] === 'collage' ? 'center' : 'start'

  const calculatePadding = () => {
    if (isMobile) {
      return '0 12px'
    }
    if (data['layout'] === 'collage' && data['imageBanner']?.length === 5) {
      return '0px'
    }
    if (hasNoTitle) {
      return '0 12px'
    }

    {
      return '0 20px'
    }
  }

  return (
    <Container
      backgroundColor={
        hasNoTitle
          ? 'none'
          : getContainerBackgroundColor(bannerLayout, bannerBackgroundColor)
      }
      borderRadius={isMobile ? 'none' : '20px'}
      id={getBemId(
        'bannerWeb',
        data['layout'].split(' ').join(''),
        `${cfIndex}`
      )}
      justifyContent='center'
      maxWidth={
        (data['layout'] === 'collage' && data['imageBanner']?.length === 5) ||
        (data['layout'] === 'collage' && data['imageBanner']?.length === 7)
          ? '1440px'
          : getContainerMaxWidth(bannerLayout, bannerExpand)
      }
      padding={calculatePadding()}
      ref={refBanner}
    >
      {isIntersectingBanner && (
        <Column
          alignItems='center'
          backgroundColor={backgroundColor}
          borderRadius={isMobile ? 'none' : '20px'}
          maxWidth={maxWidthContainer}
        >
          {hasNoTitle ? null : (
            <Column
              padding={isMobile ? '0px 0px 12px 0px' : '0px 0px 24px 0px'}
            >
              <Row>
                <Title
                  customFontSize={getGlobalStyle(
                    `--font-size-${isMobile ? 'base' : '3xl'}`
                  )}
                  fontWeight='medium'
                  headingLevel='h2'
                  text={data['title']}
                />
              </Row>
              <Row>
                <Title
                  customFontSize={getGlobalStyle(
                    `--font-size-${isMobile ? 'md' : '2xl'}`
                  )}
                  fontWeight='regular'
                  headingLevel='h3'
                  text={data['subtitle']}
                />
              </Row>
            </Column>
          )}

          <Row
            customClassName={classNames({
              [styles['bannerContainer__topBanner']]: data?.topBanner,
              [styles['bannerContainer__topBanner--mobile']]:
                data?.topBanner && isMobile
            })}
            justifyContent={justifyContentBanners}
            overflow='hidden'
          >
            <Banners
              catchPromoData={(promoData?: {
                ref?: object
                banner?: object
              }) => {
                sendPromoData({
                  cfIndex: `${cfIndex}`,
                  data: promoData.banner || data,
                  ref: promoData?.ref || promoData
                })
              }}
              imageBanner={data['imageBanner'][0]}
              infinite={bannerInfinite}
              isPriority={isPriority}
              itemsToShow={getBannerItemsToShow(
                bannerLayout,
                bannerItemsToShow
              )}
              layout={data['layout'] as BannersLayoutEnum}
              linkWrapper={Link}
              minHeight={data['height']?.toString()}
              nextImage={Image}
              saleChannel={saleChannel}
              showDots={getBannerShowDots(bannerLayout, bannerShowDots)}
              zoomIn={bannerZoomIn}
              {...bannerProps}
            />
          </Row>
        </Column>
      )}
    </Container>
  )
}
